<template>
  <v-row no-gutters>
    <v-col>
      <v-dialog v-model="erpMessageDialog" eager max-width="650" persistent>
        <v-card>
          <v-card-title>
            ERP Mesajı
            <v-spacer />
            <v-btn icon color="rgb(73, 73, 73)" @click="erpMessageDialog = false">
              <v-icon>
                {{ icons.mdiWindowClose }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text> {{ secilenErpMessage }} </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="erpMessageDialog = false"> KAPAT </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div v-if="status" id="tracking-list">
        <tracking-filter v-model="isFilterTrackingSidebarActive" :send-data="sendData" @filter-data="filterData" />

        <v-snackbars :objects.sync="snackbars" width="500" top right transition="slide-y-transition">
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <v-dialog v-model="dialogStatus" max-width="500">
          <v-card>
            <v-card-title class="text-body-1">
              #{{ dialogStatusData.id }} No'lu Sipariş Durumunu Değiştiriyorsunuz
            </v-card-title>

            <v-card-text>
              <v-select
                v-model="dialogStatusValue"
                label="Durum"
                :items="$store.state.tanimlar.siparisDurum"
                class="rounded-b-0 rounded-t-lg"
                background-color="white"
                light
                :menu-props="{ bottom: true, offsetY: true }"
                dense
                filled
                hide-details
                return-object
              ></v-select>
              <v-divider />
              <v-textarea
                v-model="dialogStatusNote"
                filled
                class="rounded-t-0 rounded-b-lg"
                background-color="white"
                light
                dense
                label="Açıklama"
                placeholder="İsteğe Bağlı"
                persistent-placeholder
                hide-details
                auto-grow
                rows="2"
              ></v-textarea>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn :disabled="loading" small text class="text-capitalize" @click="dialogStatusClose()">
                Vazgeç
              </v-btn>

              <v-btn
                :loading="loading"
                color="tertiary"
                depressed
                class="text-capitalize"
                @click="statusSave(dialogStatusData.id)"
              >
                Değiştir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogLogs" max-width="900">
          <v-card>
            <v-card-title class="text-body-1">
              #{{ dialogLogsData.id }} No'lu Sipariş için Loglar
              <v-spacer />
              <v-btn icon @click="dialogLogsClose()">
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Satır No</th>
                    <th class="text-left">Üye Id</th>
                    <th class="text-left">Durum</th>
                    <th class="text-left">Açıklama</th>
                    <th class="text-left">Tarih</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dialogLogsData.response" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.uid }}</td>
                    <td>{{ item.stext }}</td>
                    <td>{{ item.ltext }}</td>
                    <td>{{ item.create_datetime }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-text> </v-card-text>
          </v-card>
        </v-dialog>
        <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN') && $vuetify.breakpoint.xsOnly" />
        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
            <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons.mdiOrderNumericAscending }}
              </v-icon>
              Sipariş Takip
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <download-excel
              v-if="!$vuetify.breakpoint.smAndDown"
              :data="json_data"
              :fields="json_fields"
              name="SIPARIS_TAKIP__EKSUN.xls"
            >
              <v-btn plain text small>
                <v-icon left>
                  {{ icons.mdiDownload }}
                </v-icon>
                İndir
              </v-btn>
            </download-excel>

            <v-btn plain text small @click.stop="isFilterTrackingSidebarActive = !isFilterTrackingSidebarActive">
              <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
              </v-badge>
              <v-icon v-else left>
                {{ icons.mdiFilter }}
              </v-icon>
              Filitre
            </v-btn>
          </v-toolbar>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="trackingListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :server-items-length="totalTrackingListTable"
            :loading="loading"
          >
            <!-- Text -->
            <template #[`item.id`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
                {{ item.id }}
              </div>
              <span v-else class="text-caption">{{ item.id }}</span>
            </template>

            <template #[`item.fatura_adresi.name`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
                {{ item.fatura_adresi.name }}
              </div>
              <div v-else class="text-caption text-truncate" style="max-width: 330px">
                {{ item.fatura_adresi.name }}
              </div>
            </template>

            <template #[`item.durum`]="{ item }">
              <v-chip v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-3" :text-color="item.durum_color">
                {{ item.durum_txt }}
              </v-chip>

              <v-chip
                v-else
                :color="item.durum_color"
                :outlined="statusFind('siparisDurum', item.durum, 'outlined')"
                :close="userData.role === 'SUPER'"
                :close-icon="icons.mdiRefresh"
                class="special"
                label
                :disabled="disabledItems"
                @click:close="statusChange(item.id, item.durum)"
              >
                <span
                  :class="
                    statusFind('siparisDurum', item.durum, 'outlined')
                      ? null
                      : `${statusFind('siparisDurum', item.durum, 'color')}-dark--text`
                  "
                >
                  {{ item.durum_txt }}
                </span>
              </v-chip>
            </template>

            <template #[`item.siparis_tarihi`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
                {{ item.siparis_tarihi.slice(0, 16) }}
              </div>
              <span v-else class="text-caption font-weight-bold"> {{ item.siparis_tarihi.slice(0, 16) }}</span>
            </template>

            <template #[`item.odeme_tipi`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
                {{ statusFind('odemeDurum', item.odeme_tipi, 'text') }}
              </div>
              <span v-else class="text-caption font-weight-bold">
                {{ statusFind('odemeDurum', item.odeme_tipi, 'text') }}
              </span>
            </template>

            <template #[`item.erporderno`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
                {{ item.erporderno }}
              </div>
              <span v-else class="text-caption font-weight-bold">
                {{ item.erporderno }}
              </span>
            </template>

            <template #[`item.erp_msg`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
                {{ item.erp_msg }}
              </div>
              <div v-else class="text-caption text-truncate" style="max-width: 330px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="accent"
                      x-small
                      plain
                      v-bind="attrs"
                      @click="openErpMessage(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Oku</span>
                </v-tooltip>
                {{ item.erp_msg }}
              </div>
            </template>

            <template #[`item.total_price`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
                {{ item.total_price | currency }}
              </div>
              <span v-else class="success--text text-caption font-weight-bold"> {{ item.total_price | currency }}</span>
            </template>

            <template #[`item.actions`]="{ item }">
              <div v-if="$vuetify.breakpoint.xsOnly" class="pb-8 pt-4">
                <v-btn
                  depressed
                  color="secondary"
                  :disabled="disabledItems"
                  small
                  class="text-capitalize mr-3"
                  :to="{ name: 'order-tracking-view', params: { id: item.id } }"
                >
                  Ayrıntılar
                </v-btn>
                <v-btn
                  v-if="item.erporderno === null"
                  depressed
                  color="error"
                  small
                  class="text-capitalize mr-3"
                  @click="cancelOrder(item.id)"
                >
                  İptal Et
                </v-btn>

                <v-btn
                  v-if="item.isDelete === true"
                  depressed
                  color="error"
                  small
                  class="text-capitalize"
                  @click="deleteOrder(item.id)"
                >
                  Sil
                </v-btn>
              </div>
              <div v-else>
                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-r-0 rounded-l-xl"
                      :disabled="disabledItems"
                      :to="{ name: 'order-tracking-view', params: { id: item.id } }"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Ayrıntılar</span>
                </v-tooltip>
                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-0"
                      :loading="loadingItemsPdfStatus(item.id)"
                      :disabled="disabledItemsPdf"
                      v-bind="attrs"
                      @click="pdfDownload(item.id)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiDownload }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>İndir</span>
                </v-tooltip>
                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.erporderno === null && item.isDelete === true"
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-0"
                      :loading="loadingItemsStatus(item.id)"
                      :disabled="disabledItems"
                      v-bind="attrs"
                      @click="logList(item.id)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiClipboardList }}
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-l-0 rounded-r-xl"
                      :loading="loadingItemsStatus(item.id)"
                      :disabled="disabledItems"
                      v-bind="attrs"
                      @click="logList(item.id)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiClipboardList }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Loglar</span>
                </v-tooltip>
                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.erporderno === null"
                      depressed
                      color="warning"
                      x-small
                      class="rounded-0"
                      v-bind="attrs"
                      @click="cancelOrder(item.id)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiCloseCircle }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>İptal Et</span>
                </v-tooltip>

                <v-tooltip bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.isDelete === true"
                      depressed
                      color="error"
                      x-small
                      class="rounded-l-0 rounded-r-xl"
                      v-bind="attrs"
                      @click="deleteOrder(item.id)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Sil</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div v-else>
        <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN') && $vuetify.breakpoint.xsOnly" />
        <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CustomerList from '@/components/CustomerList.vue'
import router from '@/router'
import store from '@/store'
import {
  mdiClipboardList,
  mdiClose,
  mdiCloseCircle,
  mdiDelete,
  mdiDownload,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiOrderNumericAscending,
  mdiPlusThick,
  mdiRefresh,
  mdiSend,
} from '@mdi/js'
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import trackingStoreModule from '../trackingStoreModule'
import trackingFilter from './TrackingFilter.vue'

export default {
  components: {
    trackingFilter,
    VSnackbars,
    CustomerList,
  },
  setup() {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-tracking'
    const snackbars = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)
    const sendData = ref({})
    const erpMessageDialog = ref(false)
    const secilenErpMessage = ref('')
    onMounted(() => {
      if (store.state.secondState == true && store.state.secondMsg) {
        Vue.swal({
          title: 'Hata',
          html: store.state.secondMsg,
          icon: 'error',
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })
        router.push({ name: 'payment' })
      }
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        loading.value = true
        fetchTrackings()
      }

      // if (userData.role == 'PLSYR' && store.state.secondState === true) {
      //   router.push({ name: 'dashboard' })
      // }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          loading.value = true
          fetchTrackings()
        }

        // if (userData.role == 'PLSYR' && store.state.secondState === true) {
        //   router.push({ name: 'dashboard' })
        // }
      },
    )

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, trackingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
      }
    })

    const isFilterNumber = ref(0)
    const isFilterTrackingSidebarActive = ref(false)
    const trackingListTable = ref([])

    let tableColumns

    if (userData.role === 'SUPER' || userData.role === 'PLSYR') {
      debugger

      tableColumns = [
        {
          text: 'Satış No',
          value: 'id',
          width: '100px',
        },
        {
          text: 'Müşteri',
          value: 'fatura_adresi.name',
          align: 'left min-width-400',
        },
        {
          text: 'Sipariş Tarihi',
          value: 'siparis_tarihi',
          width: '150px',
        },
        {
          text: 'Durum',
          value: 'durum',
          width: '150px',
        },

        {
          text: 'Fiyat',
          value: 'total_price',
          align: 'right',
          sortable: false,
          width: '170px',
        },
        {
          text: 'Ödeme Tipi',
          value: 'odeme_tipi',
          width: '150px',
        },
        {
          text: 'ERPSiparisNo',
          value: 'erporderno',
          width: '150px',
        },

        {
          text: 'ERP Mesajı',
          value: 'erp_msg',
          width: '200px',
        },

        // {
        //   text: 'Aşama',
        //   value: 'step',
        //   width: '130px',
        //   sortable: false,
        // },

        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
          width: '250px',
        },
      ]
    } else {
      tableColumns = [
        {
          text: 'Satış No',
          value: 'id',
          width: '100px',
        },
        {
          text: 'Sipariş Tarihi',
          value: 'siparis_tarihi',
          width: '150px',
        },
        {
          text: 'Durum',
          value: 'durum',
        },

        // {
        //   text: 'Müşteri',
        //   value: 'adsoyad',
        //   align: 'left min-width-400',
        // },

        {
          text: 'Fiyat',
          value: 'total_price',
          align: 'right',
          sortable: false,
          width: '170px',
        },
        {
          text: 'Ödeme Tipi',
          value: 'odeme_tipi',
          width: '120px',
        },
        {
          text: 'ERPSiparisNo',
          value: 'erporderno',
          width: '120px',
        },

        // {
        //   text: 'Aşama',
        //   value: 'step',
        //   width: '130px',
        //   sortable: false,
        // },

        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
          width: '200px',
        },
      ]
    }

    const searchQuery = ref('')
    const urunKodFilter = ref(null)
    const idFilter = ref(null)
    const siparisDurumFilter = ref(null)
    const odemeDurumFilter = ref(null)
    const startDateFilter = ref(null)
    const endDateFilter = ref(null)
    const minPriceFilter = ref(null)
    const maxPriceFilter = ref(null)

    const statusFilter = ref(null)
    const baslikFilter = ref(null)
    const customerFilter = ref(null)
    const totalTrackingListTable = ref(0)
    const customerGroupOptions = ref([])
    const pricelistOptions = ref([])
    const loading = ref(false)
    const dialogLogs = ref(false)
    const dialogStatus = ref(false)
    const disabledItems = ref(false)
    const disabledItemsPdf = ref(false)

    const dialogStatusData = ref({})
    const dialogStatusValue = ref(null)
    const dialogStatusNote = ref(null)
    const loadingItems = ref([])
    const loadingItemsPdf = ref([])
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const trackingTotalLocal = ref([])
    const selectedRows = ref([])
    const dialogLogsData = ref({
      id: null,
      response: null,
    })

    const filterData = item => {
      idFilter.value = item.id || null
      siparisDurumFilter.value = item.siparisDurum || null
      odemeDurumFilter.value = item.odemeDurum || null
      startDateFilter.value = item.startDate || null
      endDateFilter.value = item.endDate || null
      minPriceFilter.value = item.minPrice || null
      maxPriceFilter.value = item.maxPrice || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i

      sendData.value = { ...item }
    }
    const json_data = ref([])
    const fetchTrackings = (status = false, id = null) => {
      store
        .dispatch('app-tracking/fetchTrackings', {
          q: searchQuery.value,
          options: options.value,
          'o.id': idFilter.value,
          siparisDurum: siparisDurumFilter.value,
          odemeDurum: odemeDurumFilter.value,
          startDate: startDateFilter.value,
          endDate: endDateFilter.value,
          minPrice: minPriceFilter.value,
          maxPrice: maxPriceFilter.value,
        })
        .then(response => {
          if (response.error === 0) {
            debugger
            trackingListTable.value = response.response.result
            totalTrackingListTable.value = Number(response.response.pagination.totalRec)

            json_data.value = response.response.result

            if (status) {
              dialogStatus.value = false
              snackbars.value.push({
                message: `#${id} No'lu siparişin durumu ${dialogStatusValue.value.text} olarak güncellendi.`,
                color: 'info',
                timeout: 5000,
              })
            }
            loading.value = false
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: 'Bir Hata Oluştu',
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    const trackingStatusUpdate = id => {
      store
        .dispatch('app-tracking/fetchTrackingStatusUpdate', {
          method: 'updateOrderStatus',
          id,
          status: dialogStatusValue.value.value,
          note: dialogStatusNote.value,
        })
        .then(() => {
          fetchTrackings(true, id)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const trackingLogs = id => {
      store
        .dispatch('app-tracking/fetchTrackingLogs', {
          method: 'getOrderLogs',
          id,
        })
        .then(response => {
          disabledItems.value = false
          loadingItems.value = []
          dialogLogs.value = true
          dialogLogsData.value.id = id
          dialogLogsData.value.response = response.detail
        })
        .catch(error => {
          console.log(error)
        })
    }

    const trackingPdf = id => {
      store
        .dispatch('app-tracking/fetchTrackingDownload', {
          method: 'getOrderPDF',
          id,
        })
        .then(response => {
          if (response.error === 0) {
            disabledItemsPdf.value = false
            loadingItemsPdf.value = []
            window.open(response.fileUrl)
          } else {
            Vue.swal({
              title: 'Hata',
              text: 'Bir Hata Oluştu',
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
            disabledItemsPdf.value = false
            loadingItemsPdf.value = []
          }
        })
        .catch(error => {
          Vue.swal({
            title: 'Hata',
            text: 'Bir Hata Oluştu',
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
          disabledItemsPdf.value = false
          loadingItemsPdf.value = []
        })
    }

    const cancelOrder = id => {
      store
        .dispatch('app-tracking/fetchCancelOrders', {
          method: 'cancelOrder',
          id,
        })
        .then(response => {
          debugger
          if (response.error === 0) {
            snackbars.value.push({
              message: `#${id} No'lu sipariş iptal edilmiştir.`,
              color: 'warning',
              timeout: 5000,
            })

            loading.value = true
            selectedRows.value = []
            fetchTrackings()
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: 'Bir Hata Oluştu',
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          }
        })
        .catch(error => {
          console.log(error)
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    const deleteOrder = orderId => {
      debugger
      Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          debugger
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'deleteSale',
              id: orderId,
            })
            .then(() => {
              Vue.swal({
                title: 'Silindi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
              })
              loading.value = true
              selectedRows.value = []
              fetchTrackings()
            })
        }
      })
    }

    const dialogLogsClose = () => {
      dialogLogs.value = false
    }

    const statusChange = (id, durum) => {
      dialogStatusData.value = { id, durum }
      dialogStatusValue.value = durum
      dialogStatus.value = true
    }

    const dialogStatusClose = () => {
      dialogStatusNote.value = null
      dialogStatus.value = false
    }

    const statusSave = id => {
      loading.value = true
      trackingStatusUpdate(id)
    }

    watch(
      [
        searchQuery,
        idFilter,
        siparisDurumFilter,
        odemeDurumFilter,
        startDateFilter,
        endDateFilter,
        minPriceFilter,
        maxPriceFilter,
        options,
      ],
      () => {
        loading.value = true
        selectedRows.value = []
        fetchTrackings()
      },
    )

    watch(dialogStatus, val => {
      if (!val) {
        dialogStatusClose()
      }
    })

    watch(dialogLogs, val => {
      if (!val) {
        dialogLogsClose()
      }
    })

    const statusFind = (arr, value, select) => store.state?.tanimlar?.[arr].find(item => item.value === value)?.[select]

    const logList = id => {
      loadingItems.value.push(id)
      disabledItems.value = true
      trackingLogs(id)
    }

    const pdfDownload = id => {
      loadingItemsPdf.value.push(id)
      disabledItemsPdf.value = true
      trackingPdf(id)
    }

    const openErpMessage = item => {
      erpMessageDialog.value = true
      secilenErpMessage.value = item.erp_msg
      loading.value = true
      fetchTrackings()
    }

    const loadingItemsStatus = id => loadingItems.value.includes(id)
    const loadingItemsPdfStatus = id => loadingItemsPdf.value.includes(id)

    return {
      deleteOrder,
      cancelOrder,
      pdfDownload,
      loadingItemsPdfStatus,
      disabledItemsPdf,
      sendData,
      json_data,
      dialogLogsClose,
      dialogLogsData,
      loadingItemsStatus,
      loadingItems,
      logList,
      disabledItems,
      dialogLogs,
      dialogStatusData,
      dialogStatusValue,
      dialogStatusNote,
      statusChange,
      statusSave,
      dialogStatusClose,
      dialogStatus,
      statusFind,
      isFilterNumber,
      filterData,
      trackingListTable,
      tableColumns,
      searchQuery,
      urunKodFilter,
      baslikFilter,
      idFilter,
      statusFilter,
      totalTrackingListTable,
      loading,
      options,
      trackingTotalLocal,
      isFilterTrackingSidebarActive,
      selectedRows,
      fetchTrackings,
      snackbars,
      customerFilter,
      customerGroupOptions,
      pricelistOptions,
      userData,
      status,
      openErpMessage,
      erpMessageDialog,
      secilenErpMessage,

      // icons
      icons: {
        mdiEye,
        mdiClose,
        mdiClipboardList,
        mdiOrderNumericAscending,
        mdiFilter,
        mdiDownload,
        mdiSend,
        mdiFilterOff,
        mdiRefresh,
        mdiPlusThick,
        mdiCloseCircle,
        mdiDelete,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 140 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      json_fields: {
        'Satış No': 'id',
        'Sipariş Tarihi': {
          field: 'siparis_tarihi',
          callback: value => `${value.slice(0, 16)}`,
        },
        Durum: 'durum_txt',
        Fiyat: {
          field: 'total_price',
          callback: value => `${value.slice(0, -1)}`,
        },

        'Para Birimi': 'currency',
        'Ödeme Tipi': {
          field: 'odeme_tipi',
          callback: value => (value == 2 ? 'Havale' : 'Kredi Kartı'),
        },
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.v-data-footer {
  background-color: transparent !important;
}

@media (max-width: 600px) {
  /* 600px ekran genişliğinden daha küçükse SatışNo sütununu gizle */
  .v-data-table th:nth-child(1),
  .v-data-table td:nth-child(1) {
    display: none;
  }

  /* 600px ekran genişliğinden daha küçükse ÖdemeTipi sütununu gizle */
  .v-data-table th:nth-child(6),
  .v-data-table td:nth-child(6) {
    display: none;
  }
}
</style>
